import axios from 'axios';

import {
    debug
} from "../config";

let apiUrl = "https://tgbot.mintagame.com/";

if (debug) {
    apiUrl = "http://127.0.0.1:8898/";
}

const tgLogin = function (initData, inviteId) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "tgLogin",
            {
                initData: initData,
                inviteId: inviteId
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0'
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const twitterLogin = function () {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "twitterLogin",
            {},
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0'
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const twitterVerifier = function (oauthToken, oauthVerifier, userId) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "twitterVerifier",
            {
                oauthToken: oauthToken,
                oauthVerifier: oauthVerifier,
                userId: userId,
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0'
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getVerifyRegistration = function (userId) {
    return new Promise((resolve, reject) => {
        axios.get(
            apiUrl + "getVerifyRegistration?userId=" + userId,
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getVerifyBindingAndSignIn = function (userId) {
    return new Promise((resolve, reject) => {
        axios.get(
            apiUrl + "getVerifyBindingAndSignIn?userId=" + userId,
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getUserInfoById = function (userId) {
    return new Promise((resolve, reject) => {
        axios.get(
            apiUrl + "getUserInfoById?userId=" + userId,
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getNumberInvitedUsers = function (userId, isCount, inviteStatus) {
    return new Promise((resolve, reject) => {
        axios.get(
            apiUrl + "getNumberInvitedUsers?userId=" + userId + "&isCount=" + isCount + (inviteStatus ? "&inviteStatus=" + inviteStatus : ""),
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getUserIntegral = function (userId, integralName) {
    return new Promise((resolve, reject) => {
        axios.get(
            apiUrl + "getUserIntegral?userId=" + userId + (integralName ? "&integralName=" + integralName : ""),
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getUserMining = function (userId) {
    return new Promise((resolve, reject) => {
        axios.get(
            apiUrl + "getUserMining?userId=" + userId,
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getLuckyTurntableNum = function (userId) {
    return new Promise((resolve, reject) => {
        axios.get(
            apiUrl + "getLuckyTurntableNum?userId=" + userId,
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getIntegralHistorys = function (userId, historyType, dataType, historyTime) {
    return new Promise((resolve, reject) => {
        axios.get(
            apiUrl + "getIntegralHistorys?userId=" + userId
            + (historyType ? "&historyType=" + historyType : "")
            + (dataType ? "&dataType=" + dataType : "")
            + (historyTime ? "&historyTime=" + historyTime : ""),
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getLuckyTurntableIndex = function (userId) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "getLuckyTurntableIndex",
            {
                userId: userId
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const userSignIn = function (userId) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "userSignIn",
            {
                userId: userId
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const collectInvitationPoints = function (userId, inviteId, isInvite) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "collectInvitationPoints",
            {
                userId: userId,
                inviteId: inviteId,
                isInvite: isInvite,
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const userStartMining = function (userId) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "userStartMining",
            {
                userId: userId,
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const userStopMining = function (userId, miningId) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "userStopMining",
            {
                userId: userId,
                miningId: miningId,
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getVerifyUserLogin = function (userId) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "getVerifyUserLogin",
            {
                userId: userId,
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const userBindWallet = function (userId, userAddress) {
    return new Promise((resolve, reject) => {
        axios.post(
            apiUrl + "bindWallet",
            {
                userId: userId,
                userAddress: userAddress,
            },
            {
                headers: {
                    gamePlatform: 78591001,
                    platformKey: 'g3a1m5e8k9s2a6t0',
                    userAccount: userId,
                    token: sessionStorage.getItem("userToken")
                }
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const http = {
    tgLogin,
    twitterLogin,
    twitterVerifier,
    getVerifyRegistration,
    getVerifyBindingAndSignIn,
    getUserInfoById,
    getNumberInvitedUsers,
    getUserIntegral,
    getUserMining,
    getLuckyTurntableNum,
    getIntegralHistorys,
    getLuckyTurntableIndex,
    userSignIn,
    collectInvitationPoints,
    userStartMining,
    userStopMining,
    getVerifyUserLogin,
    userBindWallet,
}

export default http;
