import Vue from 'vue';
import Vuex from 'vuex';
import * as config from './config';
/*语言包*/
import lang_zh from './tools/lang_zh.js';
import lang_en from './tools/lang_en.js';

Vue.use(Vuex);

const initState = {
    // 语言包
    lang: {cn: lang_zh, en: lang_en},
    languageType: 'en',
    //登录信息，必须初始化
    accounts: [],
    // 连接钱包窗口
    connectWalletShow: false,
    // 弹窗
    toastShow: false,
    // 0确认中... 1交易广播中... 2交易成功 3交易失败 4交易取消
    toastShowType: 0,
    // 交易成功后刷新hash，用来打开查看交易结果
    transactionHash: '',
    // 链接的链ID
    networkIDstring: 0
};

let state = {
    ...initState
};

const mutations = {
    /*
     * 设置state
     */
    setState(state, params) {
        for (let k in params) {
            state[k] = params[k];
        }
    }
};

/* 获取所有配置 */
const actions = {
    /*
     * 退出功能
     */
    logOut() {
        for (let k in state) {
            if (k === 'connectWalletShow') continue;
            state[k] = initState[k];
        }
        localStorage.removeItem('accounts');
    }
};

export default new Vuex.Store({
    state,
    mutations,
    actions
})
