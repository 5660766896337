import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import request from './tools/request';
import 'jquery';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from "element-ui/lib/locale/lang/en";
import {setUnit} from "./tools";
import {Toast, Dialog} from 'vant';
import '@vant/touch-emulator';
import VueLuckyCanvas from '@lucky-canvas/vue';

import { TonConnectUIPlugin } from '@townsquarelabs/ui-vue';


Vue.config.productionTip = false;
Vue.prototype.setUnit = setUnit;
Vue.prototype.$http = request;

Vue.use(ElementUI, {locale});
Vue.use(Toast).use(Dialog);
Vue.use(VueLuckyCanvas);

Vue.use(TonConnectUIPlugin, {
    manifestUrl: "https://task.mintagame.com/tonconnect-manifest.json"
});


new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');

