// debug true:测试  false:正式;
const devUrl = ["localhost", "127.0.0.1"];

const getEnv = () => {
    const link = window.location.href;
    let isDebug = false;
    for (let k in devUrl) {
        if (link.indexOf(devUrl[k]) !== -1) {
            isDebug = true;
        }
    }
    return isDebug;
}

// let debug = getEnv();
// let debug = true;
let debug = false;

/* 正式环境 */
// 合约地址

// 前缀
let openUrlSLD = 'bscscan.';

/* 测试环境 */
if (debug) {
    // 合约地址

    // 前缀
    openUrlSLD = 'testnet.bscscan.';
}

export {
    debug,
    openUrlSLD
}
