import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/home',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ './views/home/Index.vue'),
            meta: {
                title: 'HOME'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ './views/login/Index.vue'),
            meta: {
                title: 'LOGIN'
            }
        },
        {
            path: '/games',
            name: 'games',
            component: () => import(/* webpackChunkName: "games" */ './views/games/Index.vue'),
            meta: {
                title: 'GAMES'
            }
        },
        {
            path: '/activity',
            name: 'activity',
            component: () => import(/* webpackChunkName: "activity" */ './views/activity/Index.vue'),
            meta: {
                title: 'ACTIVITY'
            }
        },
        {
            path: '/earn',
            name: 'earn',
            component: () => import(/* webpackChunkName: "earn" */ './views/earn/Index.vue'),
            meta: {
                title: 'EARN'
            }
        },
        {
            path: '/invite',
            name: 'invite',
            component: () => import(/* webpackChunkName: "invite" */ './views/invite/Index.vue'),
            meta: {
                title: 'INVITE'
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next({
            path: '/home',
        });
    } else {
        next();
    }
});

const VueRouterPush = Router.prototype.push;

Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err);
}

export default router;
